'use client';

import React, { Suspense, useEffect, useMemo, useRef, lazy } from 'react';
import analytics from '@/lib/analytics';
import ProductSkeleton from '@/components/ProductSkeleton';
import CarouselSkeleton from '@/components/CarouselSkeleton';
import { useListingOptions } from '@/hooks/use-listing-options';
import { useStoreListingData } from '@/hooks/use-store-listing-data';
import { useStoreData } from '@/hooks/use-store-data';
import { useRelatedProducts } from '@/hooks/use-related-products';
import useErrorState from '@/stores/error-state';
import { ToastErrorType } from '@/lib/toast-errors';
import { findListingProductVariantSize } from '@/lib/product';
import { useZendeskChatbot } from '@/hooks/use-zendesk-chatbot';

const Product = lazy(() => import('@/components/Product'));
const Carousel = lazy(() => import('@/components/Carousel'));

const ListingPage = () => {
  // pull product from the ether (context)
  const { storeListing: product, productDetails } = useStoreListingData();

  const { addError } = useErrorState();

  // use hook to get product and control size/variation selection
  const { variationId, setVariationId, sizeId, setSizeId, sizeIdParam, variationIdParam } = useListingOptions(product);
  const { storeData } = useStoreData();

  // *** color no longer available
  useEffect(() => {
    if (variationIdParam && variationIdParam !== variationId) {
      addError(ToastErrorType.ColorNoLongerAvailable);
    }
  }, [addError, variationId, variationIdParam]);

  // *** size no longer available
  useEffect(() => {
    if (sizeIdParam && sizeIdParam !== sizeId) {
      addError(ToastErrorType.SizeNoLongerAvailable);
    }
  }, [addError, sizeId, sizeIdParam]);

  const { products: similarProducts, loading: similarProductsLoading } = useRelatedProducts(
    product ? [product] : [],
    'same-listing'
  );
  const { products: otherProducts, loading: otherProductsLoading } = useRelatedProducts(
    product ? [product] : [],
    'different-listing'
  );

  const pageViewed = useRef(false);
  useEffect(() => {
    if (!pageViewed.current) {
      const variantSize = findListingProductVariantSize(product, variationId, sizeId);
      const payload = {
        productId: product?.primaryProductId ?? null,
        campaignRootId: variantSize?.variant?.teespringId ?? null,
        currency: product?.currency ?? null,
        variationId: variationId ?? null,
        price: variantSize?.size?.price ?? 0,
        product: product ?? undefined,
      };
      analytics.track('view_item', payload, storeData);
      pageViewed.current = true;
    }
  }, [product, variationId, storeData, sizeId]);

  const handleVariationChange = (newVariationId: number) => {
    setVariationId(newVariationId);
    analytics.track('color_clicked', { productId: product?.primaryProductId, source: 'PDP' }, storeData);
  };

  const handleSizeChange = (newSize: number) => {
    setSizeId(newSize);
    analytics.track('size_clicked', { productId: product?.primaryProductId, source: 'PDP' }, storeData);
  };
  const currentSimilarProducts = useMemo(() => {
    return similarProducts.slice(0, 8);
  }, [similarProducts]);

  const currentOtherProducts = useMemo(() => {
    return otherProducts.slice(0, 8);
  }, [otherProducts]);

  // chatbot should be shown only in extended views
  useZendeskChatbot('extended');

  return (
    <>
      {product && (
        <div className='mx-auto max-w-4xl pb-8 lg:max-w-7xl lg:px-8'>
          <Suspense fallback={<ProductSkeleton />}>
            <Product
              product={product}
              variationId={variationId}
              handleVariationChange={handleVariationChange}
              sizeId={sizeId}
              handleSizeChange={handleSizeChange}
              productDetails={productDetails}
            />
          </Suspense>
        </div>
      )}
      <Suspense fallback={<CarouselSkeleton />}>
        <Carousel
          name='More with this design'
          productData={currentSimilarProducts}
          loading={similarProductsLoading}
          nextButtonId='nextSimilarProducts'
          prevButtonId='prevSimilarProducts'
          carouselClassName='lg:mx-auto lg:max-w-7xl pl-6 md:px-10 lg:px-8 pb-20'
          placement='PDP'
        />
      </Suspense>
      <Suspense fallback={<CarouselSkeleton />}>
        <Carousel
          name='You might also like'
          productData={currentOtherProducts}
          loading={otherProductsLoading}
          nextButtonId='nextYouMightAlsoLike'
          prevButtonId='prevYouMightAlsoLike'
          carouselClassName='lg:mx-auto lg:max-w-7xl pl-6 md:px-10 lg:px-8 pb-20'
          placement='PDP'
        />
      </Suspense>
    </>
  );
};

export default ListingPage;
