'use client';

import { useContext } from 'react';
import { StoreListingContext, StoreListingData } from '@/contexts/store-listing-context';

export const useStoreListingData = (): StoreListingData => {
  const context = useContext(StoreListingContext);
  if (context === undefined) {
    throw new Error('Error: useStoreListing must be used within a StoreListingProvider');
  }

  return {
    storeListing: context.storeListing,
    productDetails: context.productDetails,
  };
};
