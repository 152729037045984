'use client';

import React, { ReactNode, createContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProductFragment, ProductBlankFragment } from '@/gql';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';
import { redirect } from 'next/navigation';
import { ToastErrorType } from '@/lib/toast-errors';
import useErrorState from '@/stores/error-state';

export type StoreListingData = {
  storeListing?: ProductFragment | undefined;
  productDetails?: ProductBlankFragment | undefined;
};

export type StoreListingError = {
  error?: ToastErrorType | undefined;
};

export type StoreListingState = StoreListingData &
  StoreListingError & {
    storeListingDataFactory?: (previewMode?: boolean) => Promise<StoreListingData & StoreListingError>;
  };

export const StoreListingContext = createContext<StoreListingState | undefined>(undefined);

export type StoreListingProviderProps = StoreListingState & {
  children: ReactNode;
};

export const StoreListingProvider = ({
  children,
  storeListingDataFactory,
  storeListing,
  productDetails,
  error,
}: StoreListingProviderProps) => {
  const [storeListingData, setStoreListingData] = useState<StoreListingData | undefined>();
  const [activeRedirectUrl, setActiveRedirectUrl] = useState<string>('');

  // use immediate mode to get the store prevew value on the first call (as opposed to being
  // deferred to get past the first client side render) as we handle that ourselves by consuming
  // it in a useEffect()
  const previewStoreTheme = usePreviewStoreTheme(true /* immediate mode */);
  const previewMode = Boolean(previewStoreTheme?.preview);
  const inFlight = useRef<StoreListingState['storeListingDataFactory']>();
  useEffect(() => {
    const storeListingDataHandler = (data: StoreListingData & StoreListingError) => {
      if (data.error) {
        if (data.error !== ToastErrorType.PageNotFound && data.error !== ToastErrorType.ItemNoLongerAvailable) {
          // show error on current page
          const { addError } = useErrorState.getState();
          addError(data.error);
        } else {
          // show error on home page
          setActiveRedirectUrl(`/?err=${data.error}`);
        }
      } else {
        setStoreListingData({
          storeListing: data.storeListing,
          productDetails: data.productDetails,
        });
      }
    };

    // If this is preview mode (and a data factory was provided), request the preview products
    if (previewMode && storeListingDataFactory) {
      // pull new data for preview (if/when the getter function changes)
      if (inFlight.current !== storeListingDataFactory) {
        inFlight.current = storeListingDataFactory;
        storeListingDataFactory(previewMode)
          .then((result) => {
            // accept results if this is the terminal call
            if (inFlight.current === storeListingDataFactory) {
              storeListingDataHandler(result);
            }
          })
          .catch((/* e */) => {
            // eslint-disable-next-line no-console
          })
          .finally(() => {
            // clear inflight if we're the terminal call
            if (inFlight.current === storeListingDataFactory) {
              inFlight.current = undefined;
            }
          });
      }
    } else {
      // adopt passed in data
      storeListingDataHandler({ storeListing, productDetails, error });
    }
  }, [previewMode, productDetails, error, storeListing, storeListingDataFactory]);

  const state = useMemo<StoreListingState | undefined>(
    () => (storeListingData ? { ...storeListingData, storeListingDataFactory } : undefined),
    [storeListingData, storeListingDataFactory]
  );

  if (activeRedirectUrl) {
    return redirect(activeRedirectUrl); // should throw
  }

  return state ? <StoreListingContext.Provider value={state}>{children}</StoreListingContext.Provider> : null;
};
