'use client';

import { useCallback, useMemo } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

import { PrimaryProductFragment, ProductFragment, SizeFragment } from '@/gql';
import { stringToInt } from '@/lib/string';
import { selectProductVariant, selectVariantSize } from '@/lib/product';
import { notNullishOr } from '@/lib/utils';
import { SEARCH_PARAM_SIZE_ID, SEARCH_PARAM_VARIATION_ID } from '@/constants';

/**
 * Hook to manage listing product options (color/size)
 *
 * @param product The 'product' (store listing data with product info)
 * @returns
 */
export function useListingOptions(product?: ProductFragment): {
  variationId: number | undefined;
  setVariationId: (variationId: number) => void;
  sizeId: number | undefined;
  setSizeId: (sizeId: number) => void;
  sizeIdParam: number | undefined;
  variationIdParam: number | undefined;
} {
  const searchParams = useSearchParams();
  const variationIdParam = stringToInt(searchParams.get(SEARCH_PARAM_VARIATION_ID));
  const sizeIdParam = stringToInt(searchParams.get(SEARCH_PARAM_SIZE_ID));

  const router = useRouter();
  const variant = useMemo<PrimaryProductFragment | undefined>(
    () => selectProductVariant(product, variationIdParam),
    [product, variationIdParam]
  );
  const size = useMemo<SizeFragment | undefined>(() => selectVariantSize(variant, sizeIdParam), [sizeIdParam, variant]);

  const variationId = notNullishOr(variant?.variationId, undefined);
  const sizeId = notNullishOr(size?.id, undefined);

  // returned setter for variation id
  const setVariationId = useCallback(
    (newVariationId: number) => {
      if (newVariationId !== variationIdParam) {
        // start with the current url
        const url = new URL(window.location.href);
        // tweak the variation id
        url.searchParams.set(SEARCH_PARAM_VARIATION_ID, `${newVariationId}`);
        const tweakedUrl = url.toString();
        // console.log('tweakedUrl(v)=', tweakedUrl);
        router.push(tweakedUrl, { scroll: false });
      }
    },
    [router, variationIdParam]
  );

  // returned setter for size id
  const setSizeId = useCallback(
    (newSizeId: number) => {
      if (newSizeId !== sizeIdParam) {
        // start with the current url
        const url = new URL(window.location.href);
        // tweak the variation id
        url.searchParams.set(SEARCH_PARAM_SIZE_ID, `${newSizeId}`);
        const tweakedUrl = url.toString();
        // console.log('tweakedUrl(s)=', tweakedUrl);
        router.push(tweakedUrl, { scroll: false });
      }
    },
    [router, sizeIdParam]
  );

  return { variationId, setVariationId, sizeId, setSizeId, sizeIdParam, variationIdParam };
}
