import React from 'react';

const ProductSkeleton: React.FC = () => {
  return (
    <main className='bg-white flex-1' data-testid='main-skeleton-container'>
      <div className='mx-auto max-w-4xl pb-8 lg:max-w-7xl lg:px-8'>
        <div className='lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-8 lg:pt-10'>
          <div className='col-span-6 lg:col-span-7 lg:px-5 py-0'>
            <div className='flex flex-col-reverse'>
              <div className='sm:block z-10 lg:z-0 mt-[-16px] lg:mt-4'>
                <div className='flex justify-center sm:flex-row space-x-[16px]'>
                  <div
                    className='relative mr-4 border border-[#f6f6f8] h-[50px] w-[50px] lg:w-[75px] lg:h-[75px] bg-[#f6f6f8] animate-pulse rounded-md'
                    data-testid='small-image-skeleton'
                  />
                  <div
                    className='relative mr-4 border border-[#f6f6f8] h-[50px] w-[50px] lg:w-[75px] lg:h-[75px] bg-[#f6f6f8] animate-pulse rounded-md'
                    data-testid='small-image-skeleton'
                  />
                </div>
              </div>
              <div
                className='aspect-h-1 aspect-w-1 w-full z-0 bg-[#f6f6f8] md:rounded-2xl animate-pulse'
                style={{ height: '100%', width: '100%' }}
                data-testid='image-skeleton'
              />
            </div>
          </div>
          <div className='mt-10 lg:mt-0 px-6 md:px-10 lg:py-0 md:py-0 lg:px-8 col-span-6 lg:col-span-5'>
            <div
              className='lg:flex h-[56px] w-full mb-6 px-0 lg:mb-0 bg-[#f6f6f8] rounded-md animate-pulse'
              data-testid='title-skeleton'
            />
            <div className='mt-6'>
              <div className='block mt-6'>
                <div className='h-5 mb-2 bg-[#f6f6f8] animate-pulse rounded-md ' data-testid='description-skeleton' />
                <div className='inline-flex flex-wrap content-center items-center gap-2'>
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                  <div
                    className='flex items-center justify-center rounded-full p-2 h-6 w-6 bg-[#f6f6f8] animate-pulse'
                    data-testid='feature-skeleton'
                  />
                </div>
              </div>
              <div className='mt-10'>
                <div
                  className='flex items-center h-5 justify-between bg-[#f6f6f8] animate-pulse rounded-md'
                  data-testid='review-skeleton'
                />
                <div className='grid grid-cols-4 gap-4 mt-2'>
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                  <div
                    className='relative flex h-11 items-center justify-center rounded-md py-3 px-4 bg-[#f6f6f8] animate-pulse sm:flex-1'
                    data-testid='button-skeleton'
                  />
                </div>
              </div>
              <div
                className='flex flex-1 mt-10 items-center justify-center rounded-xl px-8 py-3 text-base font-medium text-white sm:w-full bg-[#f6f6f8] animate-pulse'
                data-testid='add-to-cart-skeleton'
              >
                Add to cart
              </div>
            </div>
            <div
              className='mb-2 mt-3 h-5 flex flex-1 items-center justify-center py-4 space-x-3 sm:w-full bg-[#f6f6f8] animate-pulse rounded-md'
              data-testid='second-review-skeleton'
            />
            <div
              className='mx-auto mt-2 w-full h-72 bg-[#f6f6f8] animate-pulse rounded-md'
              data-testid='additional-section-skeleton'
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductSkeleton;
